/* About.css */

/* Define a background color for the entire section */
.about-section {
    background-color: #000000;
    padding: 20px;
}

/* Style the main heading */
h2 {
    font-size: 2rem;
    color: #f7f7f7;
}

/* Style the paragraphs */
p {
    font-size: 1.3rem;
    line-height: 1.5;
    color: #f1eeee;
}

/* Style the rows */
.deal-row,
.lowdown-row,
.universe-row,
.cherry-row,
.revolution-row {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #020202;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

/* Hover effect for rows */
.deal-row:hover,
.lowdown-row:hover,
.universe-row:hover,
.cherry-row:hover,
.revolution-row:hover {
    transform: scale(1.02);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background-color: #020202;
    color: black;
    
}

/* Add some spacing between rows */
.deal-row+*,
.lowdown-row+*,
.universe-row+*,
.cherry-row+*,
.revolution-row+* {
    margin-top: 20px;
}

/* Add a subtle animation for headings */
.deal-row, .lowdown-row, .universe-row, .cherry-row, .revolution-row h2 {
    animation: fadeInUp 1.2s ease;
}

.deal-row, .lowdown-row, .universe-row, .cherry-row, .revolution-row p {
    animation: fadeInUp 2s ease;
}




.row-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #131313;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;
}
.row-container h2{
    animation: fadeInUp 2s ease;

}

/* Add some spacing between rows */
.row-container+.row-container {
    margin-top: 20px;
}



@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}




.team-button {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #444343;
    color: #ffffff;
    font-size: 1.2rem;
    padding: 20px 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;


}

.team-button:hover {
    background-color: #f7f5f5;
    color: #0e0d0d;
    transition: background-color 1.2s ease;
}

.team-button-p{
    margin-top: 3.5rem;
    margin-bottom: 2rem;

}