.hero {
    background-image: url('https://jordanandeddie.files.wordpress.com/2014/05/salinger-feature.jpg');
    background-size: cover;
    background-position: top;
    text-align: center;
    padding: 180px 0;
    animation: fadeIn 3.2s ease;

    /* Define the fadeIn animation */
}

.hero-content {
    max-width: 900px;
    margin: 0 auto;

}

.hero h1 {
    color: #1d1c1c;
    display: flex;
    justify-content: center; /* Horizontally center their contents */
    align-items: center;
    margin-top: 90px;
    margin-bottom: -10px;
    font-size: 5rem;
    cursor: default;
    transition: color 0.7s ease;
    animation: fadeIn 3.2s ease;


}

.hero h1:hover {
    color: #205d86;
    font-weight: bold;
    /* font-size: 4.1rem; */

}

.hero p {
    font-size: 1.56rem;
    margin-bottom: 50px;
    cursor: default;
    color: #1d1c1c;
    transition: color 0.7s ease;
    animation: fadeInUp 2s ease;


    &:hover {
        color: #205d86;
        /* font-size: 1.5rem; */

    }
}

.hero-cta-button {
    background-color: #070707;
    color: #fff;
    font-size: 1.2rem;
    padding: 20px 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    animation: fadeIn 2.2s ease;
    transition: background-color 0.7s ease-in-out;
    text-decoration: none;

}

.hero-cta-button:hover {
    background-color: #eceae6;
    color: #000000;
    transition: background-color .8s ease;
}



@keyframes fadeIn {
    0% {
        opacity: 0;
        /* Start with opacity 0 */
    }

    100% {
        opacity: 1;
        /* End with opacity 1 (fully visible) */
    }
}