body {
  background-color: black;
}
.team-page {
  
  display: flex;
  justify-content: space-between;
  /* Distribute columns evenly */
  max-width: 1300px;
  /* Adjust as needed */
  margin: 0 auto;
  /* Center the columns horizontally */
  animation: fadeIn 3.2s ease;
  /* Define the fadeIn animation */
}

.column {
  flex: 1;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Center text and child elements horizontally */
  justify-content: center;
  margin-top: auto;
  margin-top: -30px;
  
}

.Sadra {
  width: 79.5%;
  max-width: 100%;
  /* Adjust maximum width as needed */
  max-height: 100%;
  image-rendering: optimizeQuality;
  border-radius: 50%;
  
  
}


.Sadra h2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  color: rgb(252, 252, 252);
  font-size: 42px;
  width: 100%;
  transition: color 0.7s ease;

}

.Sadra h2:hover{
  color: #651892;
  /* font-size: 4.1rem; */
}

.Sadra h3 {
  font-size: 28px;
  color: white;
  transition: color 1.32s ease; /* Add a transition for the color change */


}
.Sadra h3:hover{
  color: rgb(0, 0, 0);
}



.Ali {
  width: 80%;
  max-width: 100%;
  /* Adjust maximum width as needed */
  max-height: 100%;
  image-rendering: optimizeQuality;
  border-radius: 50%;


}

.Ali h2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  text-align: center;
  color: rgb(248, 248, 248);
  font-size: 42px;
  width: 100%;
  transition: color 0.7s ease;

}

.Ali h2:hover{
  color: #a81611;
  /* font-size: 4.1rem; */
}
.Ali h3 {
  font-size: 28px;
  color: white;
  transition: color 1.32s ease; /* Add a transition for the color change */
}
.Ali h3:hover{
  color: rgb(0, 0, 0);
}

img {
  max-width: 65%;
  max-height: 100%;
  transition: transform 0.5s ease, filter 0.5s ease-in-out; /* Add both transitions */
  animation: fadeIn 2.2s ease;
  filter: grayscale(100%); /* Initially grayscale */
}

img:hover{
  transform: scale(1.15); /* Scale the image to 1.1 times its original size on hover */
  filter: grayscale(0%); /* Color on hover */
}

.AliSocial , .SadraSocial{ 
  background-color: #fffdfd;
  display: flex; /* Add this to make them flex containers */
  justify-content: center; /* Horizontally center their contents */
  align-items: center;
  max-width: 40%;
  max-height: 100%;
  width: 30%;
  height: 80px;
  animation: fadeIn 2.7s ease-in;
  border-radius: 40px;
  transition: background-color 0.5s ease; /* Add a smooth transition for background color change */  
}

.AliSocial:hover {
  background-color: #ee5e59;
  
}
.SadraSocial:hover {
  background-color: #bf7ae7;
  
}
@media (max-width: 768px) {
  .create-post-container {
      max-width: 90%;
      left: 5%;
  }
}


h3::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

h3:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
  
}

.Sadra h3::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background:   #651892;
  z-index: -1;
  transition: transform 1.8s ease;
}

.Ali h3::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background:  #a81611;
  z-index: -1;
  transition: transform 1.8s ease;
}

h3 {
  position: relative;
  cursor: default;
  animation: fadeIn 4.2s ease;

}



@keyframes fadeIn {
  0% {
    opacity: 0;
    /* Start with opacity 0 */
  }

  100% {
    opacity: 1;
    /* End with opacity 1 (fully visible) */
  }
}



@media (max-width: 768px) {
  .column {
    padding: 5px;
    /* Adjust padding for smaller screens */
  }

  .Sadra,
  .Ali {
    width: 100%;
    /* Make the images responsive */
    max-width: 100%;
    height: auto;
  }

  .Sadra h2,
  .Ali h2,
  .Sadra h3,
  .Ali h3 {
    font-size: 24px;
    /* Adjust font size for smaller screens */
  }
}



